<template>
  <div>
    <b-row>
      <b-col>
        <codemirror @update="scriptChanged" v-model="code" :options="cmOptions" />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-button variant="primary" @click="runCode" style="width: 100px">Run Code</b-button>
      </b-col>
    </b-row>
    <b-modal ref="my-modal" ok-only title="Execution Result" size="lg">
      <div v-if="modal.error">
        <div class="alert alert-danger" role="alert">
          <b>Error Message:</b>
          {{ modal.error_message }}
        </div>
        <div v-if="userIsAdmin" class="mt-3" style="white-space: pre; overflow: scroll">
          <b>Stacktrace:</b>
          {{ this.modal.error_stacktrace }}
        </div>
      </div>
      <div v-else>
        <vue-json-compare :oldData="modal.input" :newData="modal.output" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { isNullOrEmpty } from '@/helpers';
import VueJsonCompare from 'vue-json-compare';
import { codemirror } from 'vue-codemirror';
// eslint-disable-next-line import/no-extraneous-dependencies,import/extensions
import 'codemirror/mode/python/python.js';
// eslint-disable-next-line import/extensions,import/no-extraneous-dependencies
import 'codemirror/addon/selection/active-line.js';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'codemirror/lib/codemirror.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'codemirror/theme/base16-dark.css';
import { mapGetters } from 'vuex';

export default {
  components: {
    codemirror,
    VueJsonCompare,
  },
  props: {
    script: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      code: '',
      cmOptions: {
        autoCloseBrackets: true,
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: 'text/x-python',
        theme: 'base16-dark',
      },
      modal: {
        input: '',
        output: '',
        error: false,
        error_message: '',
        error_stacktrace: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      userIsAdmin: 'User/isAdmin',
    }),
  },
  beforeMount() {
    this.code = this.script;
  },
  methods: {
    openModal() {
      this.$refs['my-modal'].show();
    },
    async runCode() {
      try {
        const script = !isNullOrEmpty(this.code) ? Buffer.from(this.code).toString('base64') : null;
        const response = await this.$store.dispatch('Sftp/Utils/runCustomScript', { script });
        this.modal.input = response.data.input;
        this.modal.output = response.data.output;
        this.modal.error = false;
        this.openModal();
      } catch (error) {
        if (error.response.status === 400) {
          this.modal.error = true;
          this.modal.error_message = error.response.data.message.error_message;
          this.modal.error_stacktrace = error.response.data.message.error;
          this.openModal();
          return;
        }

        this.$noty.error(`An error occurred while running the code \`${error.message}\``);
      }
    },
    scriptChanged() {
      this.$emit('scriptChanged', this.code);
    },
  },
};
</script>
