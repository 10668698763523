<template>
  <b-container class='mt-4'>
    <b-spinner v-if='loading'></b-spinner>
    <div v-else>
      <b-card>
        <template #header>
          <h5>Provider Configuration</h5>
          <small>Custom configuration for this specific provider</small>
        </template>
        <b-row class='mb-2'>
          <b-col class='d-flex justify-content-end' cols='12'>
            <b-button class='ml-3' @click='showModal' type='button' :disabled='saving' id='btn_submit'
                      variant='primary'>
              <b-spinner label='Loading...' variant='light' small v-if='saving'></b-spinner>
              <span v-else>Save configuration</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row class='mb-2' cols="12">
          <b-col cols='3'>
            <label>Filepath</label>
          </b-col>
          <b-col cols='6' class="ml-2">
            <label>{{ provider.filepath }}</label>
          </b-col>
        </b-row>
        <div v-if="(provider.type === providerType.ELIGIBILITY || provider.type === providerType.CLAIMS)">
          <div v-if="(provider.type === providerType.ELIGIBILITY)">
            <b-row class='mb-2'>
              <b-col cols='3'>
                <b-form-checkbox
                    id="notification_enabled"
                    v-model="provider.notification_enabled"
                    name="notification_enabled">
                  Notifications Enabled
                </b-form-checkbox>
              </b-col>
              <b-col cols='9'>
                <b-form-group label='Notification Emails'
                  :invalid-feedback="handleError(`provider.notification_emails`)"
                  description='List of emails to notify when a new file is ingested'>
                  <b-form-input id='notification-emails' type='text' :disabled='!provider.notification_enabled'
                    :state="isValid('provider.notification_emails')" v-model='provider.notification_emails'>
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class='mb-2'>
              <b-col cols='3'>
              </b-col>
              <b-col cols='9'>
                <b-form-group label='Email subject'
                  :invalid-feedback="handleError(`provider.notification_email_subject`)"
                  description='Email subject to use on notification'>
                  <b-form-input id='notification-email-subject' type='text' :disabled='!provider.notification_enabled'
                    v-model='provider.notification_email_subject'
                    :state="isValid('provider.notification_email_subject')">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class='mb-2'>
              <b-col cols='3'>
                <b-form-checkbox
                    id="eligibility_file_dob_change_enable"
                    v-model="provider.eligibility_file_dob_change_enable"
                    name="eligibility_file_dob_change_enable">
                  Enable File Date Change
                </b-form-checkbox>
              </b-col>
              <b-col cols='9'>
                <b-form-group label='Date Format'
                              :invalid-feedback='handleError(`provider.eligibility_file_dob_format`)'
                              description='Date format that is in the file in order to convert to Sword format'>
                  <b-form-select
                    v-model='provider.eligibility_file_dob_format'
                    :options='date_format_options'
                    :disabled='!provider.eligibility_file_dob_change_enable'>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class='mb-2'>
              <b-col cols='3' class="mt-2">
                <label>Eligibility Ingestion Enabled</label>
              </b-col>
              <b-col cols='3' class="ml-2">
                <b-form-checkbox
                    id="eligibility_ingestion_enabled"
                    v-model="provider.eligibility_ingestion_enabled"
                    name="eligibility_ingestion_enabled">
                </b-form-checkbox>
              </b-col>
              <b-col cols='3' class="mt-2">
                <label>Validate Eligibility File Columns</label>
              </b-col>
              <b-col cols='2'>
                <b-form-checkbox
                    id="validate_eligibility_file_columns"
                    v-model="provider.validate_eligibility_file_columns"
                    name="validate_eligibility_file_columns">
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
          <b-row class='mb-1'>
            <b-col cols='3' class="mt-2">
              <label>Delete Files After Ingestion</label>
            </b-col>
            <b-col cols='3' class="ml-2">
              <b-form-checkbox
                  id="delete_files_after_ingestion"
                  v-model="provider.delete_files_after_ingestion"
                  name="delete_files_after_ingestion">
              </b-form-checkbox>
            </b-col>
            <template v-if="(provider.type === providerType.ELIGIBILITY)">
              <b-col cols='3' class="mt-2">
                <label>Validate Eligibility Fields</label>
              </b-col>
              <b-col cols='2'>
                <b-form-checkbox
                    id="validate_eligibility_fields"
                    v-model="provider.validate_eligibility_fields"
                    name="validate_eligibility_fields">
                </b-form-checkbox>
              </b-col>
            </template>
          </b-row>
          <b-row>
            <b-col cols='3'>
              <label> Private Key Code</label>
            </b-col>
            <b-col cols='9'>
              <b-form-group :invalid-feedback="handleError(`provider.private_key_code`)">
                <b-form-input id='private-key-code' type='text' v-model='provider.private_key_code'
                  :state="isValid('provider.private_key_code')">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class='mb-2'>
            <b-col cols='3'>
              <label>Skip Rows</label>
            </b-col>
            <b-col cols='9'>
              <b-form-group :invalid-feedback="handleError(`provider.skip_rows`)"
                description='Number of file rows to skip when the file ingestion'>
                <b-form-input id='skip-rows' type='number' min="0" :state="isValid('provider.skip_rows')"
                  v-model='provider.skip_rows'>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class='mb-2'>
            <b-col cols='3'>
              <label>Files availability (in days)</label>
            </b-col>
            <b-col cols='9'>
              <b-form-group :invalid-feedback="handleError(`provider.number_of_days_to_keep_files`)"
                            description='Number of days to keep files available in the server, after which the files will be deleted (0 = inactive)'>
                <b-form-input id='skip-rows' type='number' min="0" :state="isValid('provider.number_of_days_to_keep_files')"
                              v-model='provider.number_of_days_to_keep_files'>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <b-row class='mb-2'>
          <b-col cols='3' class="mt-2">
            <label>Allow Insecure Encryption</label>
          </b-col>
          <b-col cols='8' class="ml-2">
            <b-form-checkbox
                id="allow_insecure_encryption"
                v-model="provider.allow_insecure_encryption"
                name="allow_insecure_encryption">
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class='mb-2'>
          <b-col cols='3'>
            <label>Expected Cadence</label>
          </b-col>
          <b-col cols='9'>
            <b-form-group :invalid-feedback="handleError(`provider.expected_cadence`)"
              description='Expected to receive a file at x days'>
              <b-form-input id='expected-cadence' type='number' min="0" :state="isValid('provider.expected_cadence')"
                v-model='provider.expected_cadence'>
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class='mb-2'>
          <b-col cols='3'>
            <label>Step Configuration</label>
          </b-col>
          <b-col cols='9'>
            <b-form-group :invalid-feedback="handleError(`provider.step_configuration`)"
              description='Configure Step to be executed during ingestion'>
              <b-row>
                <b-col>
                  <TextareaAutosize class='text-area' :state="isValid('provider.step_configuration')"
                    placeholder='Step Configuration' ref='myTextarea' v-model='provider.step_configuration'
                    :min-height='200' />
                </b-col>
                <b-col cols='6'>
                  <b-card class='mb-4 pretty-text-area'>
                    <pre>{{ provider.step_configuration | pretty }}</pre>
                  </b-card>
                </b-col>
              </b-row>
              <b-row class='mt-1 mb-2'>
                <b-col class='d-flex justify-content-start'>
                  <b-button @click='prettyJson()'>JSON pretty</b-button>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <div v-if="(provider.type === providerType.ELIGIBILITY)">
          <b-row class='mb-2'>
            <b-col cols='3'>
              <label>File Tracking</label>
            </b-col>
            <b-col cols='9'>
              <b-form-group description='Check if you want to enable file tracking for this provider files'>
                <b-form-checkbox
                  id="file_tracking_enabled"
                  v-model="provider.file_tracking_enabled"
                  name="file_tracking_all_clients"
                  class='mb-2'>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <b-row class='mb-2'>
          <b-col cols='3'>
            <label>Custom Script</label>
          </b-col>
          <b-col cols='12'>
            <CustomScript @scriptChanged='updateCustomScript' :script='provider.custom_script'/>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <template #header>
          <h5>Trigger File Ingestion</h5>
        </template>
        <b-row class='mb-2'>
          <b-col cols='3'>
            <label>Filename to Ingest</label>
          </b-col>
          <b-col cols='9'>
            <b-form-select v-model="filenameToIngest" :options="files"></b-form-select>
          </b-col>
        </b-row>
        <b-row class='mt-1 mb-2'>
          <b-col class='d-flex justify-content-end'>
            <b-button @click='triggerFileIngestion()'>Ingest File</b-button>
          </b-col>
        </b-row>
        <b-modal id='modal-trigger-ingestion' ref='trigger-ingestion-modal' hide-footer hide-header>
          <div>
            <span>This file has already been successfully ingested, are you sure you want to ingest it again?</span>
          </div>
          <div class='d-flex justify-content-end mt-3'>
            <b-button class='mr-3' @click='closeModal'>Cancel</b-button>
            <b-button class='mr-3' @click='triggerFileIngestion(true)'>Ingest</b-button>
          </div>
        </b-modal>
      </b-card>
      <div class='mt-2'>
        <BigqueryImporter></BigqueryImporter>
      </div>
      <b-modal ref='save-action-modal' hide-footer title='Confirm Provider Configuration Save'>
        <div class='d-block' v-if='customScriptChanged'>
          <p>Custom Script was changed. Are you sure you want to save these configs?</p>
          <vue-json-compare :oldData='initialCustomScript' :newData='provider.custom_script' />
        </div>
        <div v-else>
          <p>Are you sure you want to save these configs?</p>
        </div>
        <b-button class='mt-3' variant='danger' block :disabled='loading' @click='acceptModalAction'>I'm Sure</b-button>
        <b-button class='mt-2' variant='info' block @click='cancelModalActions'>Cancel</b-button>
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import VueTextareaAutosize from 'vue-textarea-autosize';
import Vue from 'vue';
import VueJsonCompare from 'vue-json-compare';
import { required, minValue } from 'vuelidate/lib/validators';
import moment from 'moment';
import * as R from 'ramda';
import errorHandler from '@/mixins/errorHandler';
import helpers, { isNullOrEmpty } from '@/helpers';
import BigqueryImporter from '@/components/SFTP/BigQueryImporter/Importer.vue';
import CustomScript from './CustomScript.vue';

Vue.use(VueTextareaAutosize);

export default {
  name: 'SFTPProviderConfig',
  mixins: [ errorHandler ],
  components: {
    BigqueryImporter,
    VueJsonCompare,
    CustomScript,
  },
  computed: {
    ...mapGetters({
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
    }),
    providerType() {
      return {
        ELIGIBILITY: 'eligibility',
        CLAIMS: 'claims',
        MARKETING: 'marketing',
      };
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      provider: null,
      formValidations: [],
      filenameToIngest: '',
      files: [ { value: '', text: 'Choose file to ingest', disabled: true } ],
      selectedClients: [],
      initialCustomScript: null,
      customScriptChanged: false,
      date_format_options: [
        { text: this.generateDateOfBirthLabel('YYYY-MM-DD'), value: '%Y-%m-%d' },
        { text: this.generateDateOfBirthLabel('DD-MM-YYYY'), value: '%d-%m-%Y' },
        { text: this.generateDateOfBirthLabel('DDMMYYYY'), value: '%d%m%Y' },
        { text: this.generateDateOfBirthLabel('MM/DD/YYYY'), value: '%m/%d/%Y' },
        { text: this.generateDateOfBirthLabel('MM-DD-YYYY'), value: '%m-%d-%Y' },
        { text: this.generateDateOfBirthLabel('M/D/YYYY H:mm:ss'), value: '%m/%d/%Y %H:%M:%S' },
        { text: this.generateDateOfBirthLabel('MM/DD/YY'), value: '%m/%d/%y' },
        { text: this.generateDateOfBirthLabel('M/D/YYYY'), value: '%m/%d/%Y' },
        { text: this.generateDateOfBirthLabel('YYYYMMDD'), value: '%Y%m%d' },
        { text: this.generateDateOfBirthLabel('YYYY/DD/MM'), value: '%Y/%d/%m' },
        { text: this.generateDateOfBirthLabel('M/D/YY'), value: '%m/%d/%y}' },
      ],
    };
  },
  filters: {
    pretty(value) {
      try {
        if (isNullOrEmpty(value)) {
          return '';
        }
        if (typeof value === 'string') {
          return JSON.stringify(JSON.parse(value), null, 2);
        }
        if (value === 'object') {
          return JSON.stringify(value, null, 2);
        }

        return '';
      } catch (e) {
        return 'Invalid JSON Input!!';
      }
    },
  },
  async beforeMount() {
    if (!this.isSftpSettingsEnabled) {
      this.$router.push('/not-found');
      return;
    }

    await this.loadConfigs();
  },
  validations() {
    const {
      // eslint-disable-next-line import/no-named-as-default-member
      validEmailList, validDateFormat, validJsonFormat, validFilename,
    } = helpers;
    const cfg = {
      provider: {},
    };
    if (this.provider) {
      cfg.provider.skip_rows = { minValue: minValue(0) };
      cfg.provider.expected_cadence = { minValue: minValue(0) };
      cfg.provider.number_of_days_to_keep_files = { minValue: minValue(0) };
      cfg.provider.step_configuration = { validJsonFormat };

      if (this.provider.notification_enabled) {
        cfg.provider.notification_email_subject = { required };
        cfg.provider.notification_emails = { required, validEmailList };
      }

      if (this.provider.eligibility_file_dob_change_enable) {
        cfg.provider.eligibility_file_dob_format = { required, validDateFormat };
      }
    }

    cfg.filenameToIngest = { validFilename };
    return cfg;
  },
  methods: {
    isNullOrEmpty,
    generateDateOfBirthLabel(format) {
      return `${format} (Example: ${moment().format(format)})`;
    },
    closeModal() {
      this.$refs['trigger-ingestion-modal'].hide();
    },
    async triggerFileIngestion(force = false) {
      try {
        if (this.$v.filenameToIngest.$invalid) {
          this.$noty.error('Invalid filename sintax');
          return;
        }

        const file = this.files.find(f => f.value === this.filenameToIngest);
        if (file.ingested && !force) {
          this.$refs['trigger-ingestion-modal'].show();
          return;
        }
        await this.$store.dispatch('Sftp/Provider/triggerFileIngestion', {
          providerId: this.$route.params.providerId,
          data: {
            filename: this.filenameToIngest,
          },
        });
        this.closeModal();
        this.$noty.success('File send to ingestion');
      } catch (error) {
        this.$noty.error(`Unable to ingest file: \n${R.pathOr(error, [ 'response', 'data', 'message' ], error)}`);
      } finally {
        this.saving = false;
      }
    },
    async acceptModalAction() {
      this.hideModal();
      await this.submit();
    },
    async cancelModalActions() {
      this.hideModal();
      await this.loadConfigs();
    },
    async submit() {
      try {
        if (this.$v.provider.$invalid) {
          this.$noty.error('The configuration for provider is invalid.');
          return;
        }

        this.saving = true;
        const objProviderToSave = {
          type: this.provider.type,
          allow_insecure_encryption: this.provider.allow_insecure_encryption,
          expected_cadence: this.provider.expected_cadence,
          step_configuration: isNullOrEmpty(this.provider.step_configuration)
            ? JSON.stringify({})
            : this.provider.step_configuration,
          private_key_code: this.provider.private_key_code,
          delete_files_after_ingestion: this.provider.delete_files_after_ingestion,
          skip_rows: this.provider.skip_rows,
          notification_enabled: this.provider.notification_enabled,
          notification_emails: this.provider.notification_emails,
          notification_email_subject: this.provider.notification_email_subject,
          eligibility_file_dob_change_enable: this.provider.eligibility_file_dob_change_enable,
          eligibility_file_dob_format: this.provider.eligibility_file_dob_format,
          eligibility_ingestion_enabled: this.provider.eligibility_ingestion_enabled,
          validate_eligibility_fields: this.provider.validate_eligibility_fields,
          validate_eligibility_file_columns: this.provider.validate_eligibility_file_columns,
          number_of_days_to_keep_files: this.provider.number_of_days_to_keep_files,
          file_tracking_enabled: this.provider.file_tracking_enabled,
          custom_script: !isNullOrEmpty(this.provider.custom_script) ? Buffer.from(this.provider.custom_script).toString('base64') : null,
        };

        await this.$store.dispatch('Sftp/Provider/updateProviderConfigs', { providerId: this.$route.params.providerId, data: objProviderToSave });
        await this.loadConfigs();
        this.$noty.success('Configurations saved');
      } catch (error) {
        this.$noty.error(`Unable to save settings: \n${error}`);
      } finally {
        this.saving = false;
      }
    },
    prettyJson() {
      try {
        this.provider.step_configuration = JSON.stringify(JSON.parse(this.provider.step_configuration), null, 2);
      } catch (e) {
        this.provider.step_configuration = JSON.stringify({});
      }
    },
    async loadConfigs() {
      this.loading = true;
      const providerData = await this.$store.dispatch('Sftp/Provider/getProviderConfigs', { providerId: this.$route.params.providerId })
        .then(result => result.data);
      if (providerData) {
        this.provider = {
          id: this.$route.params.providerId,
          type: providerData.type,
          filepath: providerData.filepath,
          private_key_code: providerData.private_key_code,
          allow_insecure_encryption: !!providerData.allow_insecure_encryption || false,
          notification_emails: providerData.notification_emails,
          notification_enabled: !!providerData.notification_enabled || false,
          eligibility_ingestion_enabled: !!providerData.eligibility_ingestion_enabled || false,
          validate_eligibility_fields: !!providerData.validate_eligibility_fields || false,
          validate_eligibility_file_columns: !!providerData.validate_eligibility_file_columns || false,
          notification_email_subject: providerData.notification_email_subject,
          eligibility_file_dob_change_enable: !!providerData.eligibility_file_dob_change_enable || false,
          eligibility_file_dob_format: providerData.eligibility_file_dob_format,
          number_of_days_to_keep_files: providerData.number_of_days_to_keep_files,
          step_configuration: isNullOrEmpty(providerData.step_configuration)
            ? JSON.stringify({})
            : JSON.stringify(providerData.step_configuration),
          created_at: providerData.created_at,
          updated_at: providerData.updated_at,
          skip_rows: providerData.skip_rows,
          expected_cadence: providerData.expected_cadence,
          delete_files_after_ingestion: !!providerData.delete_files_after_ingestion || false,
          file_tracking_enabled: !!providerData.file_tracking_enabled,
          custom_script: !isNullOrEmpty(providerData.custom_script) ? Buffer.from(providerData.custom_script, 'base64').toString('ascii') : null,
        };

        // eslint-disable-next-line max-len
        this.initialCustomScript = !isNullOrEmpty(providerData.custom_script) ? Buffer.from(providerData.custom_script, 'base64').toString('ascii') : null;

        const providerFiles = await this.$store.dispatch('Sftp/Provider/getProviderFiles',
          {
            providerId: this.$route.params.providerId,
          })
          .then(result => result.data);

        // eslint-disable-next-line no-restricted-syntax
        for (const file of providerFiles) {
          this.files.push({
            value: file.filename,
            text: `${file.filename} - ${this.normalizeUpdatedAt(file.updated_at, file.ingested)}`,
            disabled: file.disabled,
            ingested: file.ingested,
          });
        }
        this.loading = false;
      }
    },
    normalizeUpdatedAt(updatedAt, ingested) {
      return (ingested ? 'last ingestion at ' : 'last file update at ') + moment(updatedAt).format('YYYY-MM-DD HH:mm');
    },
    updateCustomScript(value) {
      this.provider.custom_script = value;
    },
    showModal() {
      if (isNullOrEmpty(this.initialCustomScript) && isNullOrEmpty(this.provider.custom_script)) {
        this.$refs['save-action-modal'].show();
        this.customScriptChanged = false;
        return;
      }
      if (!isNullOrEmpty(this.initialCustomScript) || !isNullOrEmpty(this.provider.custom_script)) {
        if (!isNullOrEmpty(this.initialCustomScript) && !isNullOrEmpty(this.provider.custom_script)) {
          if (this.initialCustomScript.localeCompare(this.provider.custom_script)) {
            this.customScriptChanged = true;
            this.$refs['save-action-modal'].show();
            return;
          }
          this.customScriptChanged = false;
          this.$refs['save-action-modal'].show();
          return;
        }
        this.customScriptChanged = true;
        this.$refs['save-action-modal'].show();
      }
    },
    hideModal() {
      this.$refs['save-action-modal'].hide();
    },
  },
};
</script>

<style scoped>
.text-area {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1% 1% 1% 1%;
  min-height: 300px;
}

.pretty-text-area {
  font-family: avenir, Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #333;
}
</style>
