<template>
  <div>
    <b-card>
      <template #header>
        <h5>Schemaless Ingestion</h5>
      </template>
      <b-row class='mb-2'>
        <b-col cols='3'>
          <label>Filename to Ingest</label>
        </b-col>
        <b-col cols='9'>
          <b-form-group>
            <b-form-select v-model='filenameToIngest' :options='availableFiles'></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class='mb-2 mt-4'>
        <b-col cols='3'>
          <label>Configs</label>
        </b-col>
        <b-col cols='3'>
          <b-form-group
            label='File separator'
            description='Separator used in file'>
            <b-form-select
              id='eligibility-file-separator'
              v-model='separator'
              :options='eligibilityFileSeparatorOptions' />
          </b-form-group>
        </b-col>
        <b-col cols='3'>
          <b-form-group
            label='Skip Rows'
            description='Number of file rows to skip when the file ingestion'>
            <b-form-input
              id='skip-rows'
              type='number'
              min='0'
              v-model='skip_rows'>
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols='3'>
          <b-form-group
            label='File Has Headers'
            description='The first line of file are the headers'>
            <b-form-checkbox
              id='file_has_headers'
              v-model='has_headers'
              name='validate_eligibility_file_columns'>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

      </b-row>
      <b-row class='mt-1 mb-2'>
        <b-col class='d-flex justify-content-end'>
          <b-button :disabled='inProgress' @click='triggerFileIngestion()'>Ingest File</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h5>Last ingestion details</h5>
        </b-col>
      </b-row>
      <div v-if='loading'>
        <b-row>
          <b-col cols='12'>
            <b-spinner></b-spinner>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <IngestionDetails :ingestion='lastIngestion' :inProgress='inProgress'></IngestionDetails>
        <b-row class='mt-4 mr-2 justify-content-end'>
          <router-link
            :to="{ name: 'SFTPBigQueryImporterHistory', params: { providerId: this.$route.params.providerId }}"
            :class="['btn', 'btn-primary',  { 'disabled': isNullOrEmpty(lastIngestion) }]"
            target='_blank'
            size='sm'>
            Show History
          </router-link>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import * as R from 'ramda';
import helpers, { isNullOrEmpty } from '@/helpers';
import IngestionDetails from '@/components/SFTP/BigQueryImporter/IngestionDetails.vue';
import {
  eligibilityFileSeparatorOptions,
} from '@/scripts/definitions';
import { mapGetters } from 'vuex';

export default {
  name: 'Importer',
  components: { IngestionDetails },
  computed: {
    ...mapGetters({
      lastIngestion: 'Sftp/BigqueryImporter/getLastIngestion',
      inProgress: 'Sftp/BigqueryImporter/getInProgress',
    }),
  },
  data() {
    return {
      eligibilityFileSeparatorOptions,
      availableFiles: [],
      filenameToIngest: '',
      separator: 44,
      skip_rows: 0,
      has_headers: true,
      loading: true,
    };
  },
  validations() {
    const {
      validFilename,
    } = helpers;
    return {
      filenameToIngest: {
        validFilename,
      },
    };
  },
  async beforeMount() {
    this.loading = true;
    this.$store.commit('Sftp/BigqueryImporter/setLastIngestionResult', null);
    this.$store.commit('Sftp/BigqueryImporter/setProviderId', this.$route.params.providerId);
    await this.getAvailableFiles();
    await this.$store.dispatch('Sftp/BigqueryImporter/getLastIngestion');
    this.loading = false;
  },
  methods: {
    isNullOrEmpty,
    async triggerFileIngestion() {
      try {
        await this.$store.dispatch('Sftp/BigqueryImporter/createIngestion', {
          providerId: this.$route.params.providerId,
          data: {
            filename: this.filenameToIngest,
            separator: this.separator,
            skip_rows: this.skip_rows,
            has_headers: this.has_headers,
          },
        });
        this.$store.commit('Sftp/BigqueryImporter/setLastIngestionResult', null);
        this.$noty.success('File sent to ingestion');
      } catch (error) {
        this.$noty.error(`Unable to ingest file: \n${R.pathOr(error, [ 'response', 'data', 'message' ], error)}`);
      } finally {
        this.saving = false;
      }
    },
    async getAvailableFiles() {
      try {
        const { data } = await this.$store.dispatch('Sftp/BigqueryImporter/getFiles', {
          providerId: this.$route.params.providerId,
        });
        this.availableFiles = data;
      } catch (error) {
        this.availableFiles = [];
        this.$noty.error(error.message);
      }
    },
  },
};
</script>
<style>
</style>
